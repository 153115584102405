import React, {Component} from 'react';
import  './HomeSectionFour.css';
import Contact from '../Contact/Contact';

class HomeSectionFour extends Component {
  
  render(){

    return <section className="" id="home-section-four">
      <div className="home-section-content">
        <Contact name="contactform"/>
      </div>
    </section>
  }

}

export default HomeSectionFour;
